import React from 'react'
import { FaGithub, FaGithubAlt, FaTwitter } from 'react-icons/fa'
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://github.com/dumpofmemory?tab=repositories">
          <FaGithub className="social-icon github-icon"></FaGithub>
        </a>
      </li>
      <li></li>
      <li>
        <a href="https://twitter.com/info5am">
          <FaTwitter className="social-icon twitter-icon"></FaTwitter>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
