import React from 'react'
import SocialLinks from '../constants/socialLinks'
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>
          &copy;{new Date().getFullYear()} infosam.space, built by Sam with
          {/* {` `} */}
          {/* <a href="https://www.gatsbyjs.org">Gatsby</a> */}
          <img className="stack-svg-icons" src="/stack-icons/gatsby-icon.svg" />
          <img className="stack-svg-icons" src="/stack-icons/react-icon.svg" />
          <img className="stack-svg-icons" src="/stack-icons/js-icon.svg" />
          <img
            className="stack-svg-icons"
            src="/stack-icons/styled-components-icon.png"
          />
          <img
            className="stack-svg-icons"
            src="/stack-icons/markdown-icon.svg"
          />
          <img
            className="stack-svg-icons"
            src="/stack-icons/netlify-icon.svg"
          />
        </p>
        <SocialLinks styleClass="footer-icons" />
      </div>
    </footer>
  )
}

export default Footer
